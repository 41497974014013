import { useBaseOperateStore } from '@/store';
import { DirectiveBinding, ObjectDirective, VNode } from 'vue';


function resolveEl(el: HTMLElement, binding: DirectiveBinding<any>, sourceVnode: VNode) {
  const baseOperateStore = useBaseOperateStore();
  if (!baseOperateStore.operates) {
    return;
  }

  const operate = baseOperateStore.operates?.find((item) => binding.value === item.key);

  if (!operate) {
    el.parentNode?.removeChild(el);
  }
}

export const vJuouOperate: ObjectDirective<HTMLElement, any> = {
  mounted(el, binding, sourceVnode) {
    resolveEl(el, binding, sourceVnode);
  },
  updated(el, binding, sourceVnode) {
    resolveEl(el, binding, sourceVnode);
  },
};
