/**
 * JuouProTable
 * @description: JuouProTable
 * @author: ruixiaozi
 * @since: 2.0.0
 */
import { definePropsUtil, defineEmitsUtil } from '@/utils';
import { FormItemRule } from 'element-plus';
import { PropType, VNode } from 'vue';
import { JuouProFormItem } from '../JuouProForm';

export default {
  juouProTableProps: definePropsUtil({
    dialogWidth: {
      type: Number,
      required: false,
      default: 450,
    },
    operationWidth: {
      type: Number,
      required: false,
      default: 300,
    },
    operationMinWidth: {
      type: Number,
      required: false,
      default: 140,
    },
    columns: {
      type: Array as PropType<JuouProTableColumn[]>,
      required: true,
    },
    paginations: {
      type: Object as PropType<JuouProTablePagitions>,
      required: false,
    },
    getData: {
      type: Function as PropType<JuouProTableGetDataFunction>,
      required: true,
    },
    onDelete: {
      type: Function as PropType<(row: any) => Promise<void> | void>,
      required: false,
    },
    isDangderDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    formatDangderDeleteInfo: {
      type: Function as PropType< (row?: any) => string>,
      required: false,
    },
    onEdit: {
      type: Function as PropType<(row: any) => Promise<void> | void>,
      required: false,
    },
    onAdd: {
      type: Function as PropType<(row: any) => Promise<void> | void>,
      required: false,
    },
    operates: {
      type: Array as PropType<{
        key: string;
        name: string;
        handler: (row: any) => Promise<void> | void;
      }[]>,
      required: false,
    },
    batchOperates: {
      type: Array as PropType<{
        key: string;
        name: string;
        handler: (row: any) => Promise<void> | void;
      }[]>,
      required: false,
    },
    topOperates: {
      type: Array as PropType<{
        key: string;
        name: string;
        handler: () => Promise<void> | void;
      }[]>,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    coditionInitValues: {
      type: Object,
      required: false,
    },
    addInitValues: {
      type: Object,
      required: false,
    },
    fixedOperate: {
      type: Boolean,
      required: false,
      default: false,
    },
    // 弹窗内的表单是否为紧凑型labelWidth，默认false，默认自动以最宽的label对齐。紧凑型各自label前后不留空
    compactFormLabelWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    selection: {
      type: Boolean,
      required: false,
      default: false,
    },
    onSelected: {
      type: Function as PropType< (rows: any[]) => Promise<void> | void>,
      required: false,
    },
    addText: {
      type: String,
      required: false,
    },
    editText: {
      type: String,
      required: false,
    },
    deleteText: {
      type: String,
      required: false,
    },
    rowKey: {
      type: String,
      required: false,
    },
    defaultExpandAll: {
      type: Boolean,
      default: false,
      required: false,
    },
  }),
  juouProTableEmits: defineEmitsUtil({

  }),
};

export type JuouProTableColumn = JuouProFormItem & {
  // 是否作为筛选条件，默认false
  isCondition?: boolean;
  // 再创建界面隐藏，默认false
  hideCreate?: boolean;
  // 再编辑界面隐藏，默认false
  hideEdit?: boolean;
  // 不在table中显示
  hideTable?: boolean;
  fixed?: 'left' | 'right';
  render?: ((scope: any) => string | VNode | Promise<string | VNode>)
    | ({
      type: string;
      text?: string;
      onClick?: (scope: any) => any;
    });
  // 手动刷新，会显示一个刷新图标，点击执行此方法
  manualRefresh?: (row: any) => Promise<void> | void;
  width?: number;
  editRule?: FormItemRule[];
  addRule?: FormItemRule[];
  editDisabled?: boolean;
  addDisabled?: boolean;
};

export type JuouProTablePagitions = {
  pageSize: number;
};

export type JuouProTableFilter = {
  conditions: any;
  paginations: {
    page: number;
    pageSize: number;
    total: number;
  };
};

export type JuouProTableData = {
  total: number;
  data: any[];
};

export type JuouProTableGetDataFunction = (filter: JuouProTableFilter) => Promise<JuouProTableData> | JuouProTableData;
