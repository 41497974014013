/**
 * JuouProForm
 * @description: JuouProForm
 * @author: ruixiaozi
 * @since: 1.0.0
 */
import { definePropsUtil, defineEmitsUtil } from '@/utils';
import { PropType } from 'vue';
import { JuouProFormItem } from './JuouProForm.define';

export default {
  juouFormItemsProps: definePropsUtil({
    items: {
      type: Array as PropType<JuouProFormItem[]>,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    setCurrentValue: {
      type: Function,
      required: true,
    },
    isOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
  }),
  juouFormItemsEmits: defineEmitsUtil({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:modelValue': (_val: any) => true,
  }),
};
