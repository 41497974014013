/**
 * JuouSideBar
 * @description: JuouSideBar
 * @author: ruixiaozi
 * @since: 2.0.0
 */
import { definePropsUtil, defineEmitsUtil } from '@/utils';

export default {
  juouSideBarProps: definePropsUtil({
    modelValue: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  }),
  juouSideBarEmits: defineEmitsUtil({
    'update:modelValue': (_val: boolean) => true,
  }),
};

