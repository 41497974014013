/**
 * JuouDangerDialog
 * @description: JuouDangerDialog
 * @author: ruixiaozi
 * @since: 2.0.0
 */
import { definePropsUtil, defineEmitsUtil } from '@/utils';
import { PropType } from 'vue';

export default {
  juouDangerDialogProps: definePropsUtil({
    title: {
      type: String,
      required: false,
    },
    formatDangderDeleteInfo: {
      type: Function as PropType<(row: any) => string>,
      required: false,
    },
    onOk: {
      type: Function as PropType<(row: any) => Promise<void> | void>,
      required: false,
    },
    onCancel: {
      type: Function as PropType<() => Promise<void> | void>,
      required: false,
    },
    textOk: {
      type: String,
      required: false,
    },
    textCancel: {
      type: String,
      required: false,
    },
    hideOk: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    optName: {
      type: String,
      required: false,
    },

  }),
  juouDangerDialogEmits: defineEmitsUtil({

  }),
};

