import { useApi } from '@/hooks/useApi';
import { defineStore } from 'pinia';
import { useBaseTenantStore } from './baseTenantStore';

export class BaseMenuVo {

  public id?: number;

  // 菜单连接地址，相对路径为路由，绝对路径则跳转。如果type为link，必填
  public url?: string;

  // 菜单关联的路由path列表
  public pathList?: string[];

  public type!: string;

  public title!: string;

  // 图标
  public icon?: string;

  // 顺序，值小靠前
  public sequence!: number;

  public parentId?: number;

  // 子菜单
  public children?: BaseMenuVo[];

}


export interface BaseMenuState {
  _menus?: BaseMenuVo[];
  tenantMap?: {
    [key: number]: {
      menus?: BaseMenuVo[];
    }
  }
}

const flatMenu = (menus: BaseMenuVo[] = []) => {
  const res: BaseMenuVo[] = [];
  menus.forEach((item) => {
    res.push(item);
    if (item.children?.length) {
      res.push(...flatMenu(item.children));
    }
  });
  return res;
};


export const useBaseMenuStore = defineStore({
  id: 'juoumanagebase-menu',
  state: (): BaseMenuState => ({
    _menus: undefined,
    tenantMap: {},
  }),
  getters: {
    menus: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.menus : state._menus;
    },
    sideMenus() {
      return this.menus || [];
    },
    flatSideMenus() {
      return flatMenu(this.sideMenus as any);
    },
  },
  actions: {
    async getMenus() {
      try {
        const res = await useApi().baseMenuApi.getsBaseMenu();
        console.log(res.data.data);
        if (res.data.data) {
          const tenantId = useBaseTenantStore().tenantInfo?.id;
          if (tenantId) {
            let tenantMenuInfo = this.tenantMap?.[tenantId];
            if (!tenantMenuInfo) {
              tenantMenuInfo = {};
              this.tenantMap![tenantId] = tenantMenuInfo;
            }
            tenantMenuInfo!.menus = res.data.data;
          } else {
            this._menus = res.data.data;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    resetMenus() {
      this.$reset();
    },
  },
});
