/**
 * JuouAvatar
 * @description: JuouAvatar
 * @author: ruixiaozi
 * @since: 2.0.0
 */
import { definePropsUtil, defineEmitsUtil } from '@/utils';

export default {
  juouAvatarProps: definePropsUtil({

  }),
  juouAvatarEmits: defineEmitsUtil({

  }),
};

