/**
 * JuouUserBar
 * @description: JuouUserBar
 * @author: ruixiaozi
 * @since: 2.0.0
 */
import { definePropsUtil, defineEmitsUtil } from '@/utils';

export default {
  juouUserBarProps: definePropsUtil({

  }),
  juouUserBarEmits: defineEmitsUtil({

  }),
};

