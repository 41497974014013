import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

// pinia persist
export const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export { piniaPersistConfig } from './config';

export * from './modules';

export { defineStore } from 'pinia';
