import { App } from 'vue';
import { Components } from './components';
import { Directives } from './directive';
import _App from './App.vue';
import { initRouter } from './router';
import { JuouComponentsMap, RouteComponent } from './router/router.define';
import ElementPlus, { ElMessage } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import 'element-plus/dist/index.css';
import './styles/global.scss';
import 'normalize.css';
import { initApi, useApi } from './hooks/useApi';
import 'dayjs/locale/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import axios from 'axios';
import { useBaseUserStore, pinia, useBaseConfigStore, useBaseTenantStore } from './store';
import { JuouManageThemeOption, setJuouManageTheme } from './utils';
import { createRouter, createWebHistory, RouteRecordRaw, useRoute } from 'vue-router';
import '@/assets';

export * from './components';
export * from './directive';


export { useBaseAsync } from './hooks/useAsync';


export interface JuouManageBaseOption {
  siteName: string;
  logo?: string;
  description?: string;
  baseApiPath: string;
  viewMap: JuouComponentsMap;
  themeOption?: JuouManageThemeOption;
  adminLayoutCnt?: RouteComponent;
  topNav?: boolean;
  onLogout?: () => void | Promise<void>;
  basePath?: string;
  titleAlwaysShowSiteName?: boolean;
}

// 导出app
export const JuouManageBaseUIApp = _App;

// 导出pinia
export * from './store';

// 导出路由
export * from './router/router.define';

export { default as axios } from 'axios';


// 安装组件等
export const JuouManageBaseUI = {
  // eslint-disable-next-line max-lines-per-function
  install: (app: App, option: JuouManageBaseOption) => {
    initApi(option.baseApiPath);
    app.use(pinia);
    const tenantStore = useBaseTenantStore();
    const configStore = useBaseConfigStore();
    const baseUserStore = useBaseUserStore();
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use((request) => {
      request.headers.Authorization = baseUserStore?.token;
      request.headers['tenantid'] = tenantStore?.tenantId || tenantStore?.tenantInfo?.id;
      return request;
    });

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.config?.headers['custom-error']) {
          return Promise.reject(error);
        }
        if (error.response.status !== 401 && error.response.status !== 403) {
          ElMessage.error(error.response.data?.msg
          || (error.response.data && typeof error.response.data === 'string'
            ? error.response.data
            : '请求失败'));
          return Promise.reject(error);
        }

        // 退出登录
        baseUserStore.logout();
        return Promise.reject(error);
      },
    );

    let baseUrl: string | undefined = (option.basePath || process.env.BASE_URL);
    let tenantStr: string | undefined;
    if (location.pathname) {
      const splitePath = location.pathname.split('/');
      tenantStr = splitePath.find((item) => item.startsWith('___tenant_'));
      if (tenantStr) {
        // 获取租户信息
        const tenantId = Number(tenantStr.substring('___tenant_'.length));
        if (tenantId) {
          tenantStore.setTenant({
            id: tenantId,
          });
          if (baseUrl?.endsWith('/')) {
            baseUrl += tenantStr;
          } else {
            baseUrl += `/${tenantStr}`;
          }
          tenantStore.getTenant(tenantId).then(() => 0);
        } else {
          tenantStore.setTenant(undefined);
        }
      }
    }


    const router = createRouter({
      history: createWebHistory(baseUrl),
      routes: [],
    });
    // 引用router
    app.use(router);
    if (tenantStr) {
      if (location.pathname === `/${tenantStr}`) {
        router.replace({
          path: '/',
          query: [...new URLSearchParams(location.search).entries()].reduce((res, [key, value]) => {
            res[key] = value;
            return res;
          }, {} as any),
          hash: location.hash,
        });
      } else {
        router.replace({
          path: location.pathname.replace(`/${tenantStr}`, ''),
          query: [...new URLSearchParams(location.search).entries()].reduce((res, [key, value]) => {
            res[key] = value;
            return res;
          }, {} as any),
          hash: location.hash,
        });
      }
    }


    if (option.onLogout) {
      baseUserStore.setOnLogout(option.onLogout);
    }
    configStore.setSiteName(tenantStore.tenantInfo?.name || option.siteName);
    if (option.logo) {
      configStore.setLogo(option.logo);
    }
    if (option.description) {
      configStore.setDescription(option.description);
    }
    configStore.setTopNav(Boolean(option.topNav));


    // 注册组件
    Components.forEach((item) => {
      app.component(item.__name || item.name, item);
    });
    // 注册指令
    Object.entries(Directives).forEach(([key, value]) => {
      app.directive(
        key.slice(1)
          .replace(/(?<k1>[a-z])(?<k2>[A-Z])/ug, '$1-$2')
          .toLowerCase(),
        value,
      );
    });


    initRouter(option.siteName, option.viewMap, router, option.adminLayoutCnt, option.titleAlwaysShowSiteName);
    app.use(ElementPlus, {
      locale: zhCn,
    });
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component);
    }
    // 设置主题
    setJuouManageTheme(option.themeOption);
  },
};
