/**
 * JuouBrand
 * @description: JuouBrand
 * @author: ruixiaozi
 * @since: 2.0.0
 */
import { definePropsUtil, defineEmitsUtil } from '@/utils';

export default {
  juouBrandProps: definePropsUtil({

  }),
  juouBrandEmits: defineEmitsUtil({

  }),
};

