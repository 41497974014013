import { useApi } from '@/hooks/useApi';
import { defineStore } from 'pinia';
import { useBaseTenantStore } from './baseTenantStore';

export class BaseOperateVo {

  public id?: number;

  public key!: string;

  public name!: string;

  public routePath!: string;

}

export interface BaseOperateState {
  _operates?: BaseOperateVo[];
  tenantMap?: {
    [key: number]: {
      operates?: BaseOperateVo[];
    }
  }
}


export const useBaseOperateStore = defineStore({
  id: 'juoumanagebase-operate',
  state: (): BaseOperateState => ({
    _operates: undefined,
    tenantMap: {},
  }),
  getters: {
    operates: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.operates : state._operates;
    },
  },
  actions: {
    async getOperates() {
      try {
        const res = await useApi().baseOperateApi.getOperatesBaseOperate();
        console.log(res.data.data);
        if (res.data.data) {
          const tenantId = useBaseTenantStore().tenantInfo?.id;
          if (tenantId) {
            let tenantOperateInfo = this.tenantMap?.[tenantId];
            if (!tenantOperateInfo) {
              tenantOperateInfo = {};
              this.tenantMap![tenantId] = tenantOperateInfo;
            }
            tenantOperateInfo!.operates = res.data.data;
          } else {
            this._operates = res.data.data;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        const tenantId = useBaseTenantStore().tenantInfo?.id;
        if (tenantId) {
          let tenantOperateInfo = this.tenantMap?.[tenantId];
          if (!tenantOperateInfo) {
            tenantOperateInfo = {};
            this.tenantMap![tenantId] = tenantOperateInfo;
          }
          tenantOperateInfo!.operates = tenantOperateInfo?.operates || [];
        } else {
          this._operates = this.operates || [];
        }
      }
    },
    resetOperates() {
      this.$reset();
    },
  },
});
