import { useApi } from '@/hooks/useApi';
import { defineStore } from 'pinia';
import { piniaPersistConfig } from '../config';
import { useBaseTenantStore } from './baseTenantStore';

export interface BaseUserState {
  _token: string;
  _username: string;
  _phone: number;
  _avatar: string;
  _nickname: string;
  _meta: any;
  onLogout?: () => void | Promise<void>;
  tenantMap?: {
    [key: number]: {
      token: string;
      username: string;
      phone: number;
      avatar: string;
      nickname: string;
      meta: any;
    }
  }
}

export const useBaseUserStore = defineStore({
  id: 'juoumanagebase-user',
  state: (): BaseUserState => ({
    _token: '',
    _username: '',
    _phone: 0,
    _avatar: '',
    _nickname: '',
    _meta: {},
    tenantMap: {},
  }),
  getters: {
    token: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.token : state._token;
    },
    username: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.username : state._username;
    },
    phone: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.phone : state._phone;
    },
    avatar: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.avatar : state._avatar;
    },
    nickname: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.nickname : state._nickname;
    },
    meta: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.meta : state._meta;
    },
  },
  actions: {
    setOnLogout(_fun: () => void | Promise<void>) {
      this.onLogout = _fun;
    },
    setLoginInfo(data: any) {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      if (tenantId) {
        let tenantUserInfo = this.tenantMap?.[tenantId];
        if (!tenantUserInfo) {
          tenantUserInfo = {
            token: '',
            username: '',
            phone: 0,
            avatar: '',
            nickname: '',
            meta: {},
          };
          this.tenantMap![tenantId] = tenantUserInfo;
        }
        tenantUserInfo!.token = data.accessToken || '';
        tenantUserInfo!.username = data.username || '';
        tenantUserInfo!.phone = data.phone || 0;
        tenantUserInfo!.meta = data.meta || {};
      } else {
        this._token = data.accessToken || '';
        this._username = data.username || '';
        this._phone = data.phone || 0;
        this._meta = data.meta || {};
      }
    },
    async login(usernameOrPhone: string, password: string) {
      try {
        const res = await useApi().baseUserApi.loginBaseUser(usernameOrPhone, password);
        if (res.data.data) {
          this.setLoginInfo(res.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getUserInfo() {
      try {
        const res = await useApi().baseUserApi.getUserInfoBaseUser();
        if (res.data.data) {
          this.setLoginInfo(res.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    logout() {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      if (tenantId) {
        delete this.tenantMap?.[tenantId];
      } else {
        this._token = '';
        this._username = '';
        this._phone = 0;
        this._nickname = '';
        this._avatar = '';
        this._meta = {};
      }
      // this.$reset();
      if (this.onLogout) {
        Promise.resolve(this.onLogout());
      }
    },
  },
  persist: piniaPersistConfig('juoumanagebase-user'),
});
