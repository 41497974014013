import chroma from 'chroma-js';

export interface JuouManageThemeOption {
  primaryColor: string;
  bgColor: string;
  bgColorHover: string;
  borderRadius: string;
}

const defaultThemeOption: JuouManageThemeOption = {
  primaryColor: '#006cb2',
  bgColor: '#ffffff',
  bgColorHover: '#006cb2',
  borderRadius: '8px',
};

export function setJuouManageTheme(option: JuouManageThemeOption = defaultThemeOption) {
  const el = document.documentElement;
  el.style.setProperty('--el-color-primary', option.primaryColor);
  // light
  [3, 5, 7, 8, 9].forEach((lightNum) => {
    el.style.setProperty(`--el-color-primary-light-${lightNum}`, chroma(option.primaryColor).brighten(0.3 * lightNum)
      .hex());
  });
  el.style.setProperty('--el-border-radius-base', option.borderRadius);
  el.style.setProperty('--el-border-radius-small', option.borderRadius);
  el.style.setProperty('--el-border-radius-round', `${parseInt(option.borderRadius, 10) * 3}px`);
  el.style.setProperty('--el-border-radius-circle', '100%');


  // 自有颜色命名，用于组件，以便后期替换组件库
  el.style.setProperty(
    '--juou-primary-color',
    option.primaryColor,
  );
  el.style.setProperty(
    '--juou-primary-color--hover',
    chroma(option.primaryColor).luminance() >= 0.5
      ? chroma(option.primaryColor).darken(0.6)
        .hex()
      : chroma(option.primaryColor).brighten(0.6)
        .hex(),
  );
  // 如果primary作为背景色，则primary-text-color作为文字颜色
  el.style.setProperty(
    '--juou-primary-text-color',
    chroma(option.primaryColor).luminance() >= 0.5 ? '#000' : '#fff',
  );
  el.style.setProperty(
    '--juou-bg-color',
    option.bgColor,
  );
  el.style.setProperty(
    '--juou-bg-color-hover',
    option.bgColorHover,
  );
  el.style.setProperty(
    '--juou-bg-text-color',
    chroma(option.bgColor).luminance() >= 0.5 ? '#000' : '#fff',
  );
  el.style.setProperty(
    '--juou-bg-text-color-hover',
    chroma(option.bgColorHover).luminance() >= 0.5 ? '#000' : '#fff',
  );
  el.style.setProperty(
    '--juou-border-radius',
    option.borderRadius,
  );
}
