/* tslint:disable */
/* eslint-disable */
/**
 * juou-managebase - API文档
 * <b>注意：仅开启swagger选项有效，建议上线前关闭该选项</b>  
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const BASEMENUTYPEE = {
    Link: 'link',
    Dir: 'dir'
} as const;

export type BASEMENUTYPEE = typeof BASEMENUTYPEE[keyof typeof BASEMENUTYPEE];


/**
 * 
 * @export
 * @enum {string}
 */

export const BASEROUTETYPEE = {
    AdminPage: 'admin_page',
    CommonPage: 'common_page'
} as const;

export type BASEROUTETYPEE = typeof BASEROUTETYPEE[keyof typeof BASEROUTETYPEE];


/**
 * 
 * @export
 * @interface BaseApiAdminVo
 */
export interface BaseApiAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseApiAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'swaggerPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseApiAdminVo
     */
    'allowAll': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseApiAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseApiAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseDictionaryAdminVo
 */
export interface BaseDictionaryAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseDictionaryAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseDictionaryAdminVo
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BaseDictionaryAdminVo
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof BaseDictionaryAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseLogAdminVo
 */
export interface BaseLogAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'startId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'endId'?: number;
}
/**
 * 
 * @export
 * @interface BaseMenuAdminVo
 */
export interface BaseMenuAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'url'?: string;
    /**
     * 
     * @type {BASEMENUTYPEE}
     * @memberof BaseMenuAdminVo
     */
    'type': BASEMENUTYPEE;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'icon'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {boolean}
     * @memberof BaseMenuAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseMenuAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseMenuAdminVo
     */
    'routeIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface BaseOperateAdminVo
 */
export interface BaseOperateAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseOperateAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseOperateAdminVo
     */
    'roleIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseOrganizationAdminVo
 */
export interface BaseOrganizationAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseOrganizationAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BaseOrganizationAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BasePageQueryVo
 */
export interface BasePageQueryVo {
    /**
     * 
     * @type {number}
     * @memberof BasePageQueryVo
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof BasePageQueryVo
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BaseResultVo
 */
export interface BaseResultVo {
    /**
     * 
     * @type {boolean}
     * @memberof BaseResultVo
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseResultVo
     */
    'msg'?: string;
    /**
     * 
     * @type {any}
     * @memberof BaseResultVo
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @interface BaseRoleAdminVo
 */
export interface BaseRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseRoleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseRoleAdminVo
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseRouteAdminVo
 */
export interface BaseRouteAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseRouteAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {BASEROUTETYPEE}
     * @memberof BaseRouteAdminVo
     */
    'type': BASEROUTETYPEE;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'allowAll': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'componentName': string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'isNest': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'redirect'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseRouteAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseRouteAdminVo
     */
    'roleIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface BaseTenantAdminVo
 */
export interface BaseTenantAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseTenantAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseTenantAdminVo
     */
    'name': string;
    /**
     * 
     * @type {any}
     * @memberof BaseTenantAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {string}
     * @memberof BaseTenantAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseUserAdminVo
 */
export interface BaseUserAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseUserAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseUserAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {any}
     * @memberof BaseUserAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseUserAdminVo
     */
    'roleIds': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseUserAdminVo
     */
    'orgCodes'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseUserAdminVo
     */
    'routeIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseUserAdminVo
     */
    'menuIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface BaseUserRoleAdminVo
 */
export interface BaseUserRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseUserRoleAdminVo
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof BaseUserRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseUserRoleAdminVo
     */
    'roleId': number;
}
/**
 * 
 * @export
 * @interface PartialBaseApiAdminVo
 */
export interface PartialBaseApiAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseApiAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'swaggerPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseApiAdminVo
     */
    'allowAll'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseApiAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseApiAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseDictionaryAdminVo
 */
export interface PartialBaseDictionaryAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseDictionaryAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseDictionaryAdminVo
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseDictionaryAdminVo
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseDictionaryAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseLogAdminVo
 */
export interface PartialBaseLogAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'startId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'endId'?: number;
}
/**
 * 
 * @export
 * @interface PartialBaseMenuAdminVo
 */
export interface PartialBaseMenuAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'url'?: string;
    /**
     * 
     * @type {BASEMENUTYPEE}
     * @memberof PartialBaseMenuAdminVo
     */
    'type'?: BASEMENUTYPEE;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'icon'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'sequence'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseMenuAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseMenuAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseMenuAdminVo
     */
    'routeIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface PartialBaseOperateAdminVo
 */
export interface PartialBaseOperateAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOperateAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseOperateAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseOrganizationAdminVo
 */
export interface PartialBaseOrganizationAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseRoleAdminVo
 */
export interface PartialBaseRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRoleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRoleAdminVo
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseRouteAdminVo
 */
export interface PartialBaseRouteAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRouteAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {BASEROUTETYPEE}
     * @memberof PartialBaseRouteAdminVo
     */
    'type'?: BASEROUTETYPEE;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'allowAll'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'componentName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'isNest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'redirect'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRouteAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseRouteAdminVo
     */
    'roleIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface PartialBaseScheduleAdminVo
 */
export interface PartialBaseScheduleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleInterval'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleCurrent'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleNextTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseTenantAdminVo
 */
export interface PartialBaseTenantAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseTenantAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseTenantAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {any}
     * @memberof PartialBaseTenantAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseTenantAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseUserAdminVo
 */
export interface PartialBaseUserAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseUserAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseUserAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {any}
     * @memberof PartialBaseUserAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseUserAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialBaseUserAdminVo
     */
    'orgCodes'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseUserAdminVo
     */
    'routeIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseUserAdminVo
     */
    'menuIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject1
 */
export interface SystemGenerateObject1 {
    /**
     * 
     * @type {PartialBaseApiAdminVo}
     * @memberof SystemGenerateObject1
     */
    'conditions': PartialBaseApiAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject10
 */
export interface SystemGenerateObject10 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject10
     */
    'conditions': PartialBaseRoleAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject11
 */
export interface SystemGenerateObject11 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject11
     */
    'userId': number;
    /**
     * 
     * @type {Array<BaseUserRoleAdminVo>}
     * @memberof SystemGenerateObject11
     */
    'userRoleVos': Array<BaseUserRoleAdminVo>;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject12
 */
export interface SystemGenerateObject12 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject12
     */
    'conditions': PartialBaseRoleAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject13
 */
export interface SystemGenerateObject13 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject13
     */
    'conditions': PartialBaseRoleAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject13
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject14
 */
export interface SystemGenerateObject14 {
    /**
     * 
     * @type {PartialBaseUserAdminVo}
     * @memberof SystemGenerateObject14
     */
    'conditions': PartialBaseUserAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject15
 */
export interface SystemGenerateObject15 {
    /**
     * 
     * @type {PartialBaseUserAdminVo}
     * @memberof SystemGenerateObject15
     */
    'conditions': PartialBaseUserAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject15
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject16
 */
export interface SystemGenerateObject16 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject16
     */
    'oldPw': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject16
     */
    'newPw': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject17
 */
export interface SystemGenerateObject17 {
    /**
     * 
     * @type {PartialBaseOrganizationAdminVo}
     * @memberof SystemGenerateObject17
     */
    'conditions': PartialBaseOrganizationAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject18
 */
export interface SystemGenerateObject18 {
    /**
     * 
     * @type {PartialBaseOrganizationAdminVo}
     * @memberof SystemGenerateObject18
     */
    'conditions': PartialBaseOrganizationAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject18
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject19
 */
export interface SystemGenerateObject19 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject19
     */
    'conditions': PartialBaseLogAdminVo;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject19
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemGenerateObject19
     */
    'isGreater': boolean;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject2
 */
export interface SystemGenerateObject2 {
    /**
     * 
     * @type {PartialBaseMenuAdminVo}
     * @memberof SystemGenerateObject2
     */
    'conditions': PartialBaseMenuAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject20
 */
export interface SystemGenerateObject20 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject20
     */
    'conditions': PartialBaseLogAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject21
 */
export interface SystemGenerateObject21 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject21
     */
    'conditions': PartialBaseLogAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject21
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject22
 */
export interface SystemGenerateObject22 {
    /**
     * 
     * @type {PartialBaseDictionaryAdminVo}
     * @memberof SystemGenerateObject22
     */
    'conditions': PartialBaseDictionaryAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject23
 */
export interface SystemGenerateObject23 {
    /**
     * 
     * @type {PartialBaseDictionaryAdminVo}
     * @memberof SystemGenerateObject23
     */
    'conditions': PartialBaseDictionaryAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject23
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject24
 */
export interface SystemGenerateObject24 {
    /**
     * 
     * @type {PartialBaseTenantAdminVo}
     * @memberof SystemGenerateObject24
     */
    'conditions': PartialBaseTenantAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject24
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject3
 */
export interface SystemGenerateObject3 {
    /**
     * 
     * @type {PartialBaseMenuAdminVo}
     * @memberof SystemGenerateObject3
     */
    'conditions': PartialBaseMenuAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject3
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject4
 */
export interface SystemGenerateObject4 {
    /**
     * 
     * @type {PartialBaseOperateAdminVo}
     * @memberof SystemGenerateObject4
     */
    'conditions': PartialBaseOperateAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject5
 */
export interface SystemGenerateObject5 {
    /**
     * 
     * @type {PartialBaseOperateAdminVo}
     * @memberof SystemGenerateObject5
     */
    'conditions': PartialBaseOperateAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject5
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject6
 */
export interface SystemGenerateObject6 {
    /**
     * 
     * @type {PartialBaseRouteAdminVo}
     * @memberof SystemGenerateObject6
     */
    'conditions': PartialBaseRouteAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject7
 */
export interface SystemGenerateObject7 {
    /**
     * 
     * @type {PartialBaseRouteAdminVo}
     * @memberof SystemGenerateObject7
     */
    'conditions': PartialBaseRouteAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject7
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject8
 */
export interface SystemGenerateObject8 {
    /**
     * 
     * @type {PartialBaseScheduleAdminVo}
     * @memberof SystemGenerateObject8
     */
    'conditions': PartialBaseScheduleAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject8
     */
    'pageQuery': BasePageQueryVo;
}

/**
 * BaseApiApi - axios parameter creator
 * @export
 */
export const BaseApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApisBaseApi: async (systemGenerateObject1: SystemGenerateObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject1' is not null or undefined
            assertParamExists('listApisBaseApi', 'systemGenerateObject1', systemGenerateObject1)
            const localVarPath = `/__base/api/listApis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiBaseApi: async (baseApiAdminVo: BaseApiAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseApiAdminVo' is not null or undefined
            assertParamExists('updateApiBaseApi', 'baseApiAdminVo', baseApiAdminVo)
            const localVarPath = `/__base/api/updateApi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseApiAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseApiApi - functional programming interface
 * @export
 */
export const BaseApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listApisBaseApi(systemGenerateObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApiBaseApi(baseApiAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseApiApi - factory interface
 * @export
 */
export const BaseApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseApiApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: any): AxiosPromise<any> {
            return localVarFp.listApisBaseApi(systemGenerateObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateApiBaseApi(baseApiAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseApiApi - object-oriented interface
 * @export
 * @class BaseApiApi
 * @extends {BaseAPI}
 */
export class BaseApiApi extends BaseAPI {
    /**
     * 
     * @summary 获取接口列表
     * @param {SystemGenerateObject1} systemGenerateObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApiApi
     */
    public listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: AxiosRequestConfig) {
        return BaseApiApiFp(this.configuration).listApisBaseApi(systemGenerateObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改接口
     * @param {BaseApiAdminVo} baseApiAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApiApi
     */
    public updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: AxiosRequestConfig) {
        return BaseApiApiFp(this.configuration).updateApiBaseApi(baseApiAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseDictionaryApi - axios parameter creator
 * @export
 */
export const BaseDictionaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDictionaryBaseDictionary: async (baseDictionaryAdminVo: BaseDictionaryAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseDictionaryAdminVo' is not null or undefined
            assertParamExists('addDictionaryBaseDictionary', 'baseDictionaryAdminVo', baseDictionaryAdminVo)
            const localVarPath = `/__base/dictionary/addDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseDictionaryAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除字典
         * @param {number} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDictionaryBaseDictionary: async (dictionaryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryId' is not null or undefined
            assertParamExists('delDictionaryBaseDictionary', 'dictionaryId', dictionaryId)
            const localVarPath = `/__base/dictionary/delDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dictionaryId !== undefined) {
                localVarQueryParameter['dictionaryId'] = dictionaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取单个字典
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryBaseDictionary: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getDictionaryBaseDictionary', 'key', key)
            const localVarPath = `/__base/dictionary/getDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取字典列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDictionaryBaseDictionary: async (systemGenerateObject22: SystemGenerateObject22, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject22' is not null or undefined
            assertParamExists('listDictionaryBaseDictionary', 'systemGenerateObject22', systemGenerateObject22)
            const localVarPath = `/__base/dictionary/listDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject22, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取字典列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDictionaryBaseDictionary: async (systemGenerateObject23: SystemGenerateObject23, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject23' is not null or undefined
            assertParamExists('pageDictionaryBaseDictionary', 'systemGenerateObject23', systemGenerateObject23)
            const localVarPath = `/__base/dictionary/pageDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject23, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDictionaryBaseDictionary: async (baseDictionaryAdminVo: BaseDictionaryAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseDictionaryAdminVo' is not null or undefined
            assertParamExists('updateDictionaryBaseDictionary', 'baseDictionaryAdminVo', baseDictionaryAdminVo)
            const localVarPath = `/__base/dictionary/updateDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseDictionaryAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseDictionaryApi - functional programming interface
 * @export
 */
export const BaseDictionaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseDictionaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDictionaryBaseDictionary(baseDictionaryAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除字典
         * @param {number} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delDictionaryBaseDictionary(dictionaryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delDictionaryBaseDictionary(dictionaryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取单个字典
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionaryBaseDictionary(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionaryBaseDictionary(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取字典列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDictionaryBaseDictionary(systemGenerateObject22: SystemGenerateObject22, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDictionaryBaseDictionary(systemGenerateObject22, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取字典列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDictionaryBaseDictionary(systemGenerateObject23: SystemGenerateObject23, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDictionaryBaseDictionary(systemGenerateObject23, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDictionaryBaseDictionary(baseDictionaryAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseDictionaryApi - factory interface
 * @export
 */
export const BaseDictionaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseDictionaryApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addDictionaryBaseDictionary(baseDictionaryAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除字典
         * @param {number} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDictionaryBaseDictionary(dictionaryId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delDictionaryBaseDictionary(dictionaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取单个字典
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryBaseDictionary(key: string, options?: any): AxiosPromise<any> {
            return localVarFp.getDictionaryBaseDictionary(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取字典列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDictionaryBaseDictionary(systemGenerateObject22: SystemGenerateObject22, options?: any): AxiosPromise<any> {
            return localVarFp.listDictionaryBaseDictionary(systemGenerateObject22, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取字典列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDictionaryBaseDictionary(systemGenerateObject23: SystemGenerateObject23, options?: any): AxiosPromise<any> {
            return localVarFp.pageDictionaryBaseDictionary(systemGenerateObject23, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateDictionaryBaseDictionary(baseDictionaryAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseDictionaryApi - object-oriented interface
 * @export
 * @class BaseDictionaryApi
 * @extends {BaseAPI}
 */
export class BaseDictionaryApi extends BaseAPI {
    /**
     * 
     * @summary 添加字典
     * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public addDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).addDictionaryBaseDictionary(baseDictionaryAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除字典
     * @param {number} dictionaryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public delDictionaryBaseDictionary(dictionaryId: number, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).delDictionaryBaseDictionary(dictionaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取单个字典
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public getDictionaryBaseDictionary(key: string, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).getDictionaryBaseDictionary(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取字典列表
     * @param {SystemGenerateObject22} systemGenerateObject22 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public listDictionaryBaseDictionary(systemGenerateObject22: SystemGenerateObject22, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).listDictionaryBaseDictionary(systemGenerateObject22, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取字典列表(分页)
     * @param {SystemGenerateObject23} systemGenerateObject23 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public pageDictionaryBaseDictionary(systemGenerateObject23: SystemGenerateObject23, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).pageDictionaryBaseDictionary(systemGenerateObject23, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改字典
     * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public updateDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).updateDictionaryBaseDictionary(baseDictionaryAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseLogApi - axios parameter creator
 * @export
 */
export const BaseLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountBaseLog: async (systemGenerateObject19: SystemGenerateObject19, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject19' is not null or undefined
            assertParamExists('getCountBaseLog', 'systemGenerateObject19', systemGenerateObject19)
            const localVarPath = `/__base/log/getCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject19, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogBaseLog: async (systemGenerateObject20: SystemGenerateObject20, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject20' is not null or undefined
            assertParamExists('listLogBaseLog', 'systemGenerateObject20', systemGenerateObject20)
            const localVarPath = `/__base/log/listLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject20, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageLogBaseLog: async (systemGenerateObject21: SystemGenerateObject21, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject21' is not null or undefined
            assertParamExists('pageLogBaseLog', 'systemGenerateObject21', systemGenerateObject21)
            const localVarPath = `/__base/log/pageLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject21, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogBaseLog: async (baseLogAdminVo: BaseLogAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseLogAdminVo' is not null or undefined
            assertParamExists('updateLogBaseLog', 'baseLogAdminVo', baseLogAdminVo)
            const localVarPath = `/__base/log/updateLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseLogAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseLogApi - functional programming interface
 * @export
 */
export const BaseLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountBaseLog(systemGenerateObject19, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLogBaseLog(systemGenerateObject20, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageLogBaseLog(systemGenerateObject21, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogBaseLog(baseLogAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseLogApi - factory interface
 * @export
 */
export const BaseLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseLogApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: any): AxiosPromise<any> {
            return localVarFp.getCountBaseLog(systemGenerateObject19, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: any): AxiosPromise<any> {
            return localVarFp.listLogBaseLog(systemGenerateObject20, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: any): AxiosPromise<any> {
            return localVarFp.pageLogBaseLog(systemGenerateObject21, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateLogBaseLog(baseLogAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseLogApi - object-oriented interface
 * @export
 * @class BaseLogApi
 * @extends {BaseAPI}
 */
export class BaseLogApi extends BaseAPI {
    /**
     * 
     * @summary 获取某个id的位置
     * @param {SystemGenerateObject19} systemGenerateObject19 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).getCountBaseLog(systemGenerateObject19, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表
     * @param {SystemGenerateObject20} systemGenerateObject20 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).listLogBaseLog(systemGenerateObject20, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表(分页)
     * @param {SystemGenerateObject21} systemGenerateObject21 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).pageLogBaseLog(systemGenerateObject21, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改操作
     * @param {BaseLogAdminVo} baseLogAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).updateLogBaseLog(baseLogAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseMenuApi - axios parameter creator
 * @export
 */
export const BaseMenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMenuBaseMenu: async (baseMenuAdminVo: BaseMenuAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseMenuAdminVo' is not null or undefined
            assertParamExists('addMenuBaseMenu', 'baseMenuAdminVo', baseMenuAdminVo)
            const localVarPath = `/__base/menu/addMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseMenuAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMenuBaseMenu: async (menuId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuId' is not null or undefined
            assertParamExists('delMenuBaseMenu', 'menuId', menuId)
            const localVarPath = `/__base/menu/delMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (menuId !== undefined) {
                localVarQueryParameter['menuId'] = menuId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getsBaseMenu: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/menu/gets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMenusBaseMenu: async (systemGenerateObject2: SystemGenerateObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject2' is not null or undefined
            assertParamExists('listMenusBaseMenu', 'systemGenerateObject2', systemGenerateObject2)
            const localVarPath = `/__base/menu/listMenus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMenusBaseMenu: async (systemGenerateObject3: SystemGenerateObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject3' is not null or undefined
            assertParamExists('pageMenusBaseMenu', 'systemGenerateObject3', systemGenerateObject3)
            const localVarPath = `/__base/menu/pageMenus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuBaseMenu: async (baseMenuAdminVo: BaseMenuAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseMenuAdminVo' is not null or undefined
            assertParamExists('updateMenuBaseMenu', 'baseMenuAdminVo', baseMenuAdminVo)
            const localVarPath = `/__base/menu/updateMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseMenuAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseMenuApi - functional programming interface
 * @export
 */
export const BaseMenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseMenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMenuBaseMenu(baseMenuAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delMenuBaseMenu(menuId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delMenuBaseMenu(menuId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getsBaseMenu(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getsBaseMenu(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMenusBaseMenu(systemGenerateObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageMenusBaseMenu(systemGenerateObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMenuBaseMenu(baseMenuAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseMenuApi - factory interface
 * @export
 */
export const BaseMenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseMenuApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMenuBaseMenu(menuId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delMenuBaseMenu(menuId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getsBaseMenu(options?: any): AxiosPromise<any> {
            return localVarFp.getsBaseMenu(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: any): AxiosPromise<any> {
            return localVarFp.listMenusBaseMenu(systemGenerateObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: any): AxiosPromise<any> {
            return localVarFp.pageMenusBaseMenu(systemGenerateObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseMenuApi - object-oriented interface
 * @export
 * @class BaseMenuApi
 * @extends {BaseAPI}
 */
export class BaseMenuApi extends BaseAPI {
    /**
     * 
     * @summary 添加菜单
     * @param {BaseMenuAdminVo} baseMenuAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).addMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除菜单
     * @param {number} menuId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public delMenuBaseMenu(menuId: number, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).delMenuBaseMenu(menuId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取动态菜单
     * @summary 获取动态菜单
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public getsBaseMenu(options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).getsBaseMenu(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取菜单列表
     * @param {SystemGenerateObject2} systemGenerateObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).listMenusBaseMenu(systemGenerateObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取菜单列表(分页)
     * @param {SystemGenerateObject3} systemGenerateObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).pageMenusBaseMenu(systemGenerateObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改菜单
     * @param {BaseMenuAdminVo} baseMenuAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).updateMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseOperateApi - axios parameter creator
 * @export
 */
export const BaseOperateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperateBaseOperate: async (baseOperateAdminVo: BaseOperateAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOperateAdminVo' is not null or undefined
            assertParamExists('addOperateBaseOperate', 'baseOperateAdminVo', baseOperateAdminVo)
            const localVarPath = `/__base/operate/addOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOperateAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOperateBaseOperate: async (operateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operateId' is not null or undefined
            assertParamExists('delOperateBaseOperate', 'operateId', operateId)
            const localVarPath = `/__base/operate/delOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (operateId !== undefined) {
                localVarQueryParameter['operateId'] = operateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatesBaseOperate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/operate/getOperates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperateBaseOperate: async (systemGenerateObject4: SystemGenerateObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject4' is not null or undefined
            assertParamExists('listOperateBaseOperate', 'systemGenerateObject4', systemGenerateObject4)
            const localVarPath = `/__base/operate/listOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOperateBaseOperate: async (systemGenerateObject5: SystemGenerateObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject5' is not null or undefined
            assertParamExists('pageOperateBaseOperate', 'systemGenerateObject5', systemGenerateObject5)
            const localVarPath = `/__base/operate/pageOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperateBaseOperate: async (baseOperateAdminVo: BaseOperateAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOperateAdminVo' is not null or undefined
            assertParamExists('updateOperateBaseOperate', 'baseOperateAdminVo', baseOperateAdminVo)
            const localVarPath = `/__base/operate/updateOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOperateAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseOperateApi - functional programming interface
 * @export
 */
export const BaseOperateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseOperateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOperateBaseOperate(baseOperateAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOperateBaseOperate(operateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOperateBaseOperate(operateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatesBaseOperate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatesBaseOperate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOperateBaseOperate(systemGenerateObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOperateBaseOperate(systemGenerateObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOperateBaseOperate(baseOperateAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseOperateApi - factory interface
 * @export
 */
export const BaseOperateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseOperateApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOperateBaseOperate(operateId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delOperateBaseOperate(operateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatesBaseOperate(options?: any): AxiosPromise<any> {
            return localVarFp.getOperatesBaseOperate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: any): AxiosPromise<any> {
            return localVarFp.listOperateBaseOperate(systemGenerateObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: any): AxiosPromise<any> {
            return localVarFp.pageOperateBaseOperate(systemGenerateObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseOperateApi - object-oriented interface
 * @export
 * @class BaseOperateApi
 * @extends {BaseAPI}
 */
export class BaseOperateApi extends BaseAPI {
    /**
     * 
     * @summary 添加操作
     * @param {BaseOperateAdminVo} baseOperateAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).addOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除操作
     * @param {number} operateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public delOperateBaseOperate(operateId: number, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).delOperateBaseOperate(operateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户的操作列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public getOperatesBaseOperate(options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).getOperatesBaseOperate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表
     * @param {SystemGenerateObject4} systemGenerateObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).listOperateBaseOperate(systemGenerateObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表(分页)
     * @param {SystemGenerateObject5} systemGenerateObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).pageOperateBaseOperate(systemGenerateObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改操作
     * @param {BaseOperateAdminVo} baseOperateAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).updateOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseOrganizationApi - axios parameter creator
 * @export
 */
export const BaseOrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationBaseOrganization: async (baseOrganizationAdminVo: BaseOrganizationAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOrganizationAdminVo' is not null or undefined
            assertParamExists('addOrganizationBaseOrganization', 'baseOrganizationAdminVo', baseOrganizationAdminVo)
            const localVarPath = `/__base/organization/addOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOrganizationAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOrganizationBaseOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('delOrganizationBaseOrganization', 'organizationId', organizationId)
            const localVarPath = `/__base/organization/delOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationsBaseOrganization: async (systemGenerateObject17: SystemGenerateObject17, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject17' is not null or undefined
            assertParamExists('listOrganizationsBaseOrganization', 'systemGenerateObject17', systemGenerateObject17)
            const localVarPath = `/__base/organization/listOrganizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject17, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOrganizationsBaseOrganization: async (systemGenerateObject18: SystemGenerateObject18, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject18' is not null or undefined
            assertParamExists('pageOrganizationsBaseOrganization', 'systemGenerateObject18', systemGenerateObject18)
            const localVarPath = `/__base/organization/pageOrganizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject18, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateOrganizationBaseOrganization: async (baseOrganizationAdminVo: BaseOrganizationAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOrganizationAdminVo' is not null or undefined
            assertParamExists('udpateOrganizationBaseOrganization', 'baseOrganizationAdminVo', baseOrganizationAdminVo)
            const localVarPath = `/__base/organization/udpateOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOrganizationAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseOrganizationApi - functional programming interface
 * @export
 */
export const BaseOrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseOrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganizationBaseOrganization(baseOrganizationAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOrganizationBaseOrganization(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOrganizationBaseOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizationsBaseOrganization(systemGenerateObject17, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOrganizationsBaseOrganization(systemGenerateObject18, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseOrganizationApi - factory interface
 * @export
 */
export const BaseOrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseOrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOrganizationBaseOrganization(organizationId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delOrganizationBaseOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: any): AxiosPromise<any> {
            return localVarFp.listOrganizationsBaseOrganization(systemGenerateObject17, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: any): AxiosPromise<any> {
            return localVarFp.pageOrganizationsBaseOrganization(systemGenerateObject18, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseOrganizationApi - object-oriented interface
 * @export
 * @class BaseOrganizationApi
 * @extends {BaseAPI}
 */
export class BaseOrganizationApi extends BaseAPI {
    /**
     * 
     * @summary 添加组织
     * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).addOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除组织
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public delOrganizationBaseOrganization(organizationId: number, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).delOrganizationBaseOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织列表
     * @param {SystemGenerateObject17} systemGenerateObject17 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).listOrganizationsBaseOrganization(systemGenerateObject17, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织列表(分页)
     * @param {SystemGenerateObject18} systemGenerateObject18 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).pageOrganizationsBaseOrganization(systemGenerateObject18, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改组织
     * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseRouteApi - axios parameter creator
 * @export
 */
export const BaseRouteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRouteBaseRoute: async (baseRouteAdminVo: BaseRouteAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRouteAdminVo' is not null or undefined
            assertParamExists('addRouteBaseRoute', 'baseRouteAdminVo', baseRouteAdminVo)
            const localVarPath = `/__base/route/addRoute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRouteAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoteBaseRoute: async (routeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeId' is not null or undefined
            assertParamExists('deleteRoteBaseRoute', 'routeId', routeId)
            const localVarPath = `/__base/route/deleteRote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (routeId !== undefined) {
                localVarQueryParameter['routeId'] = routeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoPermissionRoutesBaseRoute: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/route/getNoPermissionRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionRoutesBaseRoute: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/route/getPermissionRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoutesBaseRoute: async (systemGenerateObject6: SystemGenerateObject6, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject6' is not null or undefined
            assertParamExists('listRoutesBaseRoute', 'systemGenerateObject6', systemGenerateObject6)
            const localVarPath = `/__base/route/listRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRoutesBaseRoute: async (systemGenerateObject7: SystemGenerateObject7, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject7' is not null or undefined
            assertParamExists('pageRoutesBaseRoute', 'systemGenerateObject7', systemGenerateObject7)
            const localVarPath = `/__base/route/pageRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject7, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteBaseRoute: async (baseRouteAdminVo: BaseRouteAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRouteAdminVo' is not null or undefined
            assertParamExists('updateRouteBaseRoute', 'baseRouteAdminVo', baseRouteAdminVo)
            const localVarPath = `/__base/route/updateRoute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRouteAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseRouteApi - functional programming interface
 * @export
 */
export const BaseRouteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseRouteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRouteBaseRoute(baseRouteAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoteBaseRoute(routeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoteBaseRoute(routeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoPermissionRoutesBaseRoute(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoPermissionRoutesBaseRoute(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionRoutesBaseRoute(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionRoutesBaseRoute(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRoutesBaseRoute(systemGenerateObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageRoutesBaseRoute(systemGenerateObject7, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRouteBaseRoute(baseRouteAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseRouteApi - factory interface
 * @export
 */
export const BaseRouteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseRouteApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoteBaseRoute(routeId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteRoteBaseRoute(routeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoPermissionRoutesBaseRoute(options?: any): AxiosPromise<any> {
            return localVarFp.getNoPermissionRoutesBaseRoute(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionRoutesBaseRoute(options?: any): AxiosPromise<any> {
            return localVarFp.getPermissionRoutesBaseRoute(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: any): AxiosPromise<any> {
            return localVarFp.listRoutesBaseRoute(systemGenerateObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: any): AxiosPromise<any> {
            return localVarFp.pageRoutesBaseRoute(systemGenerateObject7, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseRouteApi - object-oriented interface
 * @export
 * @class BaseRouteApi
 * @extends {BaseAPI}
 */
export class BaseRouteApi extends BaseAPI {
    /**
     * 
     * @summary 添加路由
     * @param {BaseRouteAdminVo} baseRouteAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).addRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除路由
     * @param {number} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public deleteRoteBaseRoute(routeId: number, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).deleteRoteBaseRoute(routeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取无需授权的动态路由
     * @summary 获取无需授权的动态路由
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public getNoPermissionRoutesBaseRoute(options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).getNoPermissionRoutesBaseRoute(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取动态路由
     * @summary 获取动态路由
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public getPermissionRoutesBaseRoute(options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).getPermissionRoutesBaseRoute(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取路由列表
     * @param {SystemGenerateObject6} systemGenerateObject6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).listRoutesBaseRoute(systemGenerateObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取路由列表(分页)
     * @param {SystemGenerateObject7} systemGenerateObject7 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).pageRoutesBaseRoute(systemGenerateObject7, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改路由
     * @param {BaseRouteAdminVo} baseRouteAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).updateRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseScheduleApi - axios parameter creator
 * @export
 */
export const BaseScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleBaseSchedule: async (scheduleName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleName' is not null or undefined
            assertParamExists('deleteScheduleBaseSchedule', 'scheduleName', scheduleName)
            const localVarPath = `/__base/schedule/deleteSchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scheduleName !== undefined) {
                localVarQueryParameter['scheduleName'] = scheduleName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSchedulesBaseSchedule: async (systemGenerateObject8: SystemGenerateObject8, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject8' is not null or undefined
            assertParamExists('pageSchedulesBaseSchedule', 'systemGenerateObject8', systemGenerateObject8)
            const localVarPath = `/__base/schedule/pageSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject8, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseScheduleApi - functional programming interface
 * @export
 */
export const BaseScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScheduleBaseSchedule(scheduleName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScheduleBaseSchedule(scheduleName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageSchedulesBaseSchedule(systemGenerateObject8, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseScheduleApi - factory interface
 * @export
 */
export const BaseScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseScheduleApiFp(configuration)
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleBaseSchedule(scheduleName: string, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteScheduleBaseSchedule(scheduleName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: any): AxiosPromise<any> {
            return localVarFp.pageSchedulesBaseSchedule(systemGenerateObject8, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseScheduleApi - object-oriented interface
 * @export
 * @class BaseScheduleApi
 * @extends {BaseAPI}
 */
export class BaseScheduleApi extends BaseAPI {
    /**
     * 
     * @summary 删除任务
     * @param {string} scheduleName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseScheduleApi
     */
    public deleteScheduleBaseSchedule(scheduleName: string, options?: AxiosRequestConfig) {
        return BaseScheduleApiFp(this.configuration).deleteScheduleBaseSchedule(scheduleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取任务列表(分页)
     * @param {SystemGenerateObject8} systemGenerateObject8 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseScheduleApi
     */
    public pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: AxiosRequestConfig) {
        return BaseScheduleApiFp(this.configuration).pageSchedulesBaseSchedule(systemGenerateObject8, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseTenantApi - axios parameter creator
 * @export
 */
export const BaseTenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBaseTenantBaseTenant: async (baseTenantAdminVo: BaseTenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseTenantAdminVo' is not null or undefined
            assertParamExists('addBaseTenantBaseTenant', 'baseTenantAdminVo', baseTenantAdminVo)
            const localVarPath = `/__base/tenant/addBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseTenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} baseTenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delBaseTenantBaseTenant: async (baseTenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseTenantId' is not null or undefined
            assertParamExists('delBaseTenantBaseTenant', 'baseTenantId', baseTenantId)
            const localVarPath = `/__base/tenant/delBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (baseTenantId !== undefined) {
                localVarQueryParameter['baseTenantId'] = baseTenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户信息（公共）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPublicBaseTenant: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenantPublicBaseTenant', 'id', id)
            const localVarPath = `/__base/tenant/getTenantPublic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialBaseTenantAdminVo} partialBaseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBaseTenantBaseTenant: async (partialBaseTenantAdminVo: PartialBaseTenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partialBaseTenantAdminVo' is not null or undefined
            assertParamExists('listBaseTenantBaseTenant', 'partialBaseTenantAdminVo', partialBaseTenantAdminVo)
            const localVarPath = `/__base/tenant/listBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialBaseTenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBaseTenantBaseTenant: async (systemGenerateObject24: SystemGenerateObject24, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject24' is not null or undefined
            assertParamExists('pageBaseTenantBaseTenant', 'systemGenerateObject24', systemGenerateObject24)
            const localVarPath = `/__base/tenant/pageBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject24, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBaseTenantBaseTenant: async (baseTenantAdminVo: BaseTenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseTenantAdminVo' is not null or undefined
            assertParamExists('updateBaseTenantBaseTenant', 'baseTenantAdminVo', baseTenantAdminVo)
            const localVarPath = `/__base/tenant/updateBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseTenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseTenantApi - functional programming interface
 * @export
 */
export const BaseTenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseTenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBaseTenantBaseTenant(baseTenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} baseTenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delBaseTenantBaseTenant(baseTenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delBaseTenantBaseTenant(baseTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户信息（公共）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantPublicBaseTenant(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantPublicBaseTenant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialBaseTenantAdminVo} partialBaseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBaseTenantBaseTenant(partialBaseTenantAdminVo: PartialBaseTenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBaseTenantBaseTenant(partialBaseTenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageBaseTenantBaseTenant(systemGenerateObject24: SystemGenerateObject24, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageBaseTenantBaseTenant(systemGenerateObject24, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBaseTenantBaseTenant(baseTenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseTenantApi - factory interface
 * @export
 */
export const BaseTenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseTenantApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addBaseTenantBaseTenant(baseTenantAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} baseTenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delBaseTenantBaseTenant(baseTenantId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delBaseTenantBaseTenant(baseTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户信息（公共）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPublicBaseTenant(id: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.getTenantPublicBaseTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialBaseTenantAdminVo} partialBaseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBaseTenantBaseTenant(partialBaseTenantAdminVo: PartialBaseTenantAdminVo, options?: any): AxiosPromise<any> {
            return localVarFp.listBaseTenantBaseTenant(partialBaseTenantAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBaseTenantBaseTenant(systemGenerateObject24: SystemGenerateObject24, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.pageBaseTenantBaseTenant(systemGenerateObject24, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateBaseTenantBaseTenant(baseTenantAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseTenantApi - object-oriented interface
 * @export
 * @class BaseTenantApi
 * @extends {BaseAPI}
 */
export class BaseTenantApi extends BaseAPI {
    /**
     * 
     * @summary 添加租户
     * @param {BaseTenantAdminVo} baseTenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public addBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).addBaseTenantBaseTenant(baseTenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除租户
     * @param {number} baseTenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public delBaseTenantBaseTenant(baseTenantId: number, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).delBaseTenantBaseTenant(baseTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户信息（公共）
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public getTenantPublicBaseTenant(id: number, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).getTenantPublicBaseTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户列表
     * @param {PartialBaseTenantAdminVo} partialBaseTenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public listBaseTenantBaseTenant(partialBaseTenantAdminVo: PartialBaseTenantAdminVo, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).listBaseTenantBaseTenant(partialBaseTenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户列表(分页)
     * @param {SystemGenerateObject24} systemGenerateObject24 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public pageBaseTenantBaseTenant(systemGenerateObject24: SystemGenerateObject24, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).pageBaseTenantBaseTenant(systemGenerateObject24, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新租户
     * @param {BaseTenantAdminVo} baseTenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public updateBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).updateBaseTenantBaseTenant(baseTenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseUserApi - axios parameter creator
 * @export
 */
export const BaseUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleBaseUser: async (baseRoleAdminVo: BaseRoleAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRoleAdminVo' is not null or undefined
            assertParamExists('addRoleBaseUser', 'baseRoleAdminVo', baseRoleAdminVo)
            const localVarPath = `/__base/user/addRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRoleAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserBaseUser: async (baseUserAdminVo: BaseUserAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUserAdminVo' is not null or undefined
            assertParamExists('addUserBaseUser', 'baseUserAdminVo', baseUserAdminVo)
            const localVarPath = `/__base/user/addUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delRoleBaseUser: async (roleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('delRoleBaseUser', 'roleId', roleId)
            const localVarPath = `/__base/user/delRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserBaseUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserBaseUser', 'userId', userId)
            const localVarPath = `/__base/user/deleteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取用户信息
         * @summary 获取用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoBaseUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/user/getUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgRolesBaseUser: async (orgCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgCode' is not null or undefined
            assertParamExists('listOrgRolesBaseUser', 'orgCode', orgCode)
            const localVarPath = `/__base/user/listOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgCode !== undefined) {
                localVarQueryParameter['orgCode'] = orgCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRolesBaseUser: async (systemGenerateObject12: SystemGenerateObject12, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject12' is not null or undefined
            assertParamExists('listRolesBaseUser', 'systemGenerateObject12', systemGenerateObject12)
            const localVarPath = `/__base/user/listRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject12, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystemRolesBaseUser: async (systemGenerateObject10: SystemGenerateObject10, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject10' is not null or undefined
            assertParamExists('listSystemRolesBaseUser', 'systemGenerateObject10', systemGenerateObject10)
            const localVarPath = `/__base/user/listSystemRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject10, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOrgRolesBaseUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserOrgRolesBaseUser', 'userId', userId)
            const localVarPath = `/__base/user/listUserOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersBaseUser: async (systemGenerateObject14: SystemGenerateObject14, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject14' is not null or undefined
            assertParamExists('listUsersBaseUser', 'systemGenerateObject14', systemGenerateObject14)
            const localVarPath = `/__base/user/listUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject14, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginBaseUser: async (userNameOrPhone: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNameOrPhone' is not null or undefined
            assertParamExists('loginBaseUser', 'userNameOrPhone', userNameOrPhone)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginBaseUser', 'password', password)
            const localVarPath = `/__base/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (userNameOrPhone !== undefined) { 
                localVarFormParams.set('userNameOrPhone', userNameOrPhone as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBaseUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRolesBaseUser: async (systemGenerateObject13: SystemGenerateObject13, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject13' is not null or undefined
            assertParamExists('pageRolesBaseUser', 'systemGenerateObject13', systemGenerateObject13)
            const localVarPath = `/__base/user/pageRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject13, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageUsersBaseUser: async (systemGenerateObject15: SystemGenerateObject15, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject15' is not null or undefined
            assertParamExists('pageUsersBaseUser', 'systemGenerateObject15', systemGenerateObject15)
            const localVarPath = `/__base/user/pageUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject15, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateRoleBaseUser: async (baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRoleAdminVo' is not null or undefined
            assertParamExists('udpateRoleBaseUser', 'baseRoleAdminVo', baseRoleAdminVo)
            const localVarPath = `/__base/user/udpateRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRoleAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBaseUser: async (baseUserAdminVo: BaseUserAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUserAdminVo' is not null or undefined
            assertParamExists('updateUserBaseUser', 'baseUserAdminVo', baseUserAdminVo)
            const localVarPath = `/__base/user/updateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOrgRolesBaseUser: async (systemGenerateObject11: SystemGenerateObject11, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject11' is not null or undefined
            assertParamExists('updateUserOrgRolesBaseUser', 'systemGenerateObject11', systemGenerateObject11)
            const localVarPath = `/__base/user/updateUserOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject11, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPWBaseUser: async (systemGenerateObject16: SystemGenerateObject16, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject16' is not null or undefined
            assertParamExists('updateUserPWBaseUser', 'systemGenerateObject16', systemGenerateObject16)
            const localVarPath = `/__base/user/updateUserPW`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject16, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseUserApi - functional programming interface
 * @export
 */
export const BaseUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoleBaseUser(baseRoleAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserBaseUser(baseUserAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delRoleBaseUser(roleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delRoleBaseUser(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserBaseUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserBaseUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取用户信息
         * @summary 获取用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoBaseUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfoBaseUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrgRolesBaseUser(orgCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrgRolesBaseUser(orgCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRolesBaseUser(systemGenerateObject12, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSystemRolesBaseUser(systemGenerateObject10, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserOrgRolesBaseUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserOrgRolesBaseUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersBaseUser(systemGenerateObject14, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginBaseUser(userNameOrPhone: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginBaseUser(userNameOrPhone, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutBaseUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutBaseUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageRolesBaseUser(systemGenerateObject13, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageUsersBaseUser(systemGenerateObject15, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.udpateRoleBaseUser(baseRoleAdminVo, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserBaseUser(baseUserAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserOrgRolesBaseUser(systemGenerateObject11, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPWBaseUser(systemGenerateObject16, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseUserApi - factory interface
 * @export
 */
export const BaseUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseUserApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addRoleBaseUser(baseRoleAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addUserBaseUser(baseUserAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delRoleBaseUser(roleId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delRoleBaseUser(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserBaseUser(userId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteUserBaseUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取用户信息
         * @summary 获取用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoBaseUser(options?: any): AxiosPromise<any> {
            return localVarFp.getUserInfoBaseUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgRolesBaseUser(orgCode: string, options?: any): AxiosPromise<any> {
            return localVarFp.listOrgRolesBaseUser(orgCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: any): AxiosPromise<any> {
            return localVarFp.listRolesBaseUser(systemGenerateObject12, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: any): AxiosPromise<any> {
            return localVarFp.listSystemRolesBaseUser(systemGenerateObject10, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOrgRolesBaseUser(userId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.listUserOrgRolesBaseUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: any): AxiosPromise<any> {
            return localVarFp.listUsersBaseUser(systemGenerateObject14, options).then((request) => request(axios, basePath));
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginBaseUser(userNameOrPhone: string, password: string, options?: any): AxiosPromise<any> {
            return localVarFp.loginBaseUser(userNameOrPhone, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBaseUser(options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.logoutBaseUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: any): AxiosPromise<any> {
            return localVarFp.pageRolesBaseUser(systemGenerateObject13, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: any): AxiosPromise<any> {
            return localVarFp.pageUsersBaseUser(systemGenerateObject15, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.udpateRoleBaseUser(baseRoleAdminVo, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserBaseUser(baseUserAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserOrgRolesBaseUser(systemGenerateObject11, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserPWBaseUser(systemGenerateObject16, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseUserApi - object-oriented interface
 * @export
 * @class BaseUserApi
 * @extends {BaseAPI}
 */
export class BaseUserApi extends BaseAPI {
    /**
     * 
     * @summary 添加角色
     * @param {BaseRoleAdminVo} baseRoleAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).addRoleBaseUser(baseRoleAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加用户
     * @param {BaseUserAdminVo} baseUserAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).addUserBaseUser(baseUserAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除角色
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public delRoleBaseUser(roleId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).delRoleBaseUser(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除用户
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public deleteUserBaseUser(userId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).deleteUserBaseUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取用户信息
     * @summary 获取用户信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public getUserInfoBaseUser(options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).getUserInfoBaseUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织机构下角色列表
     * @param {string} orgCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listOrgRolesBaseUser(orgCode: string, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listOrgRolesBaseUser(orgCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取角色列表
     * @param {SystemGenerateObject12} systemGenerateObject12 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listRolesBaseUser(systemGenerateObject12, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取系统角色列表
     * @param {SystemGenerateObject10} systemGenerateObject10 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listSystemRolesBaseUser(systemGenerateObject10, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询用户的组织角色列表
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listUserOrgRolesBaseUser(userId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listUserOrgRolesBaseUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户列表
     * @param {SystemGenerateObject14} systemGenerateObject14 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listUsersBaseUser(systemGenerateObject14, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登录
     * @summary 登录
     * @param {string} userNameOrPhone 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public loginBaseUser(userNameOrPhone: string, password: string, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).loginBaseUser(userNameOrPhone, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登出
     * @summary 登出
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public logoutBaseUser(options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).logoutBaseUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取角色列表(分页)
     * @param {SystemGenerateObject13} systemGenerateObject13 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).pageRolesBaseUser(systemGenerateObject13, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户列表(分页)
     * @param {SystemGenerateObject15} systemGenerateObject15 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).pageUsersBaseUser(systemGenerateObject15, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改角色
     * @param {BaseRoleAdminVo} baseRoleAdminVo 
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).udpateRoleBaseUser(baseRoleAdminVo, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改用户
     * @param {BaseUserAdminVo} baseUserAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserBaseUser(baseUserAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新用户的组织角色列表
     * @param {SystemGenerateObject11} systemGenerateObject11 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserOrgRolesBaseUser(systemGenerateObject11, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改用户密码
     * @param {SystemGenerateObject16} systemGenerateObject16 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserPWBaseUser(systemGenerateObject16, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseUtilApi - axios parameter creator
 * @export
 */
export const BaseUtilApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdBaseUtil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/util/getId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdsBaseUtil: async (count: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'count' is not null or undefined
            assertParamExists('getIdsBaseUtil', 'count', count)
            const localVarPath = `/__base/util/getIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUUIDBaseUtil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/util/getUUID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseUtilApi - functional programming interface
 * @export
 */
export const BaseUtilApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseUtilApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdBaseUtil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdBaseUtil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdsBaseUtil(count: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdsBaseUtil(count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUUIDBaseUtil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUUIDBaseUtil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseUtilApi - factory interface
 * @export
 */
export const BaseUtilApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseUtilApiFp(configuration)
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdBaseUtil(options?: any): AxiosPromise<number> {
            return localVarFp.getIdBaseUtil(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdsBaseUtil(count: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getIdsBaseUtil(count, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUUIDBaseUtil(options?: any): AxiosPromise<string> {
            return localVarFp.getUUIDBaseUtil(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseUtilApi - object-oriented interface
 * @export
 * @class BaseUtilApi
 * @extends {BaseAPI}
 */
export class BaseUtilApi extends BaseAPI {
    /**
     * 获取id
     * @summary 获取id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getIdBaseUtil(options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getIdBaseUtil(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取id
     * @summary 获取ids
     * @param {number} count 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getIdsBaseUtil(count: number, options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getIdsBaseUtil(count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取uuid
     * @summary 获取uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getUUIDBaseUtil(options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getUUIDBaseUtil(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _46170000000000000000system: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/swagger.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _46170000000000000000system(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._46170000000000000000system(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _46170000000000000000system(options?: any): AxiosPromise<any> {
            return localVarFp._46170000000000000000system(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 获取swagger.json
     * @summary swagger接口文档
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public _46170000000000000000system(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration)._46170000000000000000system(options).then((request) => request(this.axios, this.basePath));
    }
}



