/**
 * JuouProForm
 * @description: JuouProForm
 * @author: ruixiaozi
 * @since: 1.0.0
 */
import { defineEmitsUtil, definePropsUtil } from '@/utils';
import { FormItemRule, UploadFile } from 'element-plus';
import { PropType } from 'vue';

export default {
  juouProFormProps: definePropsUtil({
    items: {
      type: Array as PropType<JuouProFormItem[]>,
      required: true,
    },
    initFormValue: {
      type: Object,
      required: false,
    },
    // 默认false
    isDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialogWidth: {
      type: Number,
      required: false,
      default: 450,
    },
    title: {
      type: String,
      required: false,
    },
    onOk: {
      type: Function as PropType<(values: any) => Promise<void> | void>,
      required: false,
    },
    onCancel: {
      type: Function as PropType<() => Promise<void> | void>,
      required: false,
    },
    textOk: {
      type: String,
      required: false,
    },
    textCancel: {
      type: String,
      required: false,
    },
    // 默认false
    hideOk: {
      type: Boolean,
      required: false,
      default: false,
    },
    // 默认false
    hideCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    // isInline，默认false
    isInline: {
      type: Boolean,
      required: false,
      default: false,
    },
    // 是否为紧凑型labelWidth，默认false，默认自动以最宽的label对齐。紧凑型各自label前后不留空
    compactlabelWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    // 隐藏冒号，默认false
    hideColon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    zIndex: {
      type: Number,
      required: false,
    },
  }),
  juouProFormEmits: defineEmitsUtil({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'change': (_val: any) => true,
  }),
};

export type JuouProFormItem = {
  name: string;
  label: string;
  rule?: FormItemRule[];
  placeholder?: string;
  order?: number;
  disabled?: boolean;
} & (
  | {
      type: 'number';
      min?: number;
      max?: number;
      step?: number;
    }
  | {
      type: 'numberStr';
    }
  | {
      type: 'string';
      prefix?: string | ((data: any, formData: any) => string);
      // 输入完成，或者设置值，也就是change时触发
      formatter?: (value: string | number, formData: any) => string;
      // 输入的时候触发
      parser?: (value: string, formData: any) => string;
    }
  | {
      type: 'doubleString';
      prefix?: string | ((data: any, formData: any) => string);
      // 分隔符
      separator?: string;
      placeholder2?: string;
      // 输入完成，或者设置值，也就是change时触发
      formatter?: (value: string | number, formData: any) => string;
      // 输入的时候触发
      parser?: (value: string, formData: any) => string;
    }
  | {
      type: 'select';
      options: {
        value: any;
        label: string;
      }[];
      clearable?: boolean;
    }
  | {
      type: 'multiselect';
      options: {
        value: any;
        label: string;
      }[];
    }
  | {
      type: 'treeselect';
      options: {
        value: any;
        label: string;
        children: any[];
      }[];
      multiple?: boolean;
    }
  | {
      type: 'checkbox';
      options: {
        value: any;
        label: string;
      }[];
    }
  | {
      type: 'searchSelect';
      options: {
        value: any;
        label: string;
      }[];
      clearable?: boolean;
      filterable?: boolean;
      onChange?: (formState: any) => void;
    }
  | {
      type: 'textaera';
      rows?: number;
    }
  | {
      type: 'password';
    }
  | {
      type: 'text';
      textContent?: string;
      color?: string;
    }
  | {
      type: 'link';
      onClick: () => any;
    }
  | {
      type: 'renderText';
      formRender: (data: any, formData: any) => string;
    }
  | {
      type: 'datepick';
      pickType:
        | 'date'
        | 'year'
        | 'month'
        | 'dates'
        | 'week'
        | 'datetime'
        | 'datetimerange'
        | 'daterange'
        | 'monthrange';
      startPlaceholder?: string;
      endPlaceholder?: string;
      rangeSeparator?: string;
      placeholder?: string;
      fastPick?: {
        name: string;
        handler: (formdata: any) => void;
        dateChangeHandler: (val: any) => boolean;
      }[];
      width?: string;
    }
  | {
      type: 'timepick';
      placeholder?: string;
    }
  | {
      type: 'image';
      action: string;
      headers?: { [key: string]: any };
      method?: string;
      multiple?: boolean;
      fieldName?: string;
      baseUrl?: string;
      onSuccess?: (data: any) => void;
    }
  | {
      type: 'file';
      action: string;
      headers?: { [key: string]: any };
      method?: string;
      multiple?: boolean;
      fieldName?: string;
      baseUrl?: string;
      data?: any;
      onSuccess?: (data: any, uploadFile: UploadFile) => void;
      onFailed?: (error: Error, uploadFile: UploadFile) => void;
    }
  | {
      type: 'color';
    }| {
      type: 'swtich';
      tip?: string;
      trueText?: string;
      falseText?: string;
    }
);
