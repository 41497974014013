import { useApi } from '@/hooks/useApi';
import { defineStore } from 'pinia';
import { useBaseConfigStore } from './baseConfigStore';


export interface BaseTenantState {
  // 当前登录的租户信息
  tenantInfo?: any;

  // 当前基础底座选择的租户id
  tenantId?: number;
}


export const useBaseTenantStore = defineStore({
  id: 'juoumanagebase-tenant',
  state: (): BaseTenantState => ({
    tenantInfo: undefined,
  }),
  actions: {
    setTenant(_v: any) {
      this.tenantInfo = _v;
    },
    setTenantId(_v: number) {
      this.tenantId = _v;
    },
    async getTenant(tenantId: number) {
      try {
        const res = await useApi().baseTenantApi.getTenantPublicBaseTenant(tenantId);
        if (res.data?.data?.id && res.data?.data?.name) {
          this.setTenant(res.data.data);
          const configStore = useBaseConfigStore();
          configStore.setSiteName(res.data.data.name);
        }
      } catch (error) {
        console.log(error);
      }
    },
    reset() {
      this.$reset();
    },
  },
});
