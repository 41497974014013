import { useApi } from '@/hooks/useApi';
import { defineStore } from 'pinia';
import { useBaseTenantStore } from './baseTenantStore';

class BaseRotueVo {

  public id?: number;

  public type!: string;

  public allowAll!: boolean;

  public title!: string;

  // 路由路径（相对路径，相对于父路由）
  public path!: string;

  // 组件路径（前端项目相对src路径）
  public componentName!: string;

  // 是否嵌套组件，如果开启，则父组件为父路由的组件，否则，使用最外层路由的组件作为父组件
  public isNest!: boolean;

  // 跳转路由，默认存在子路由将进入第一个有权限的子路由，可以自定义跳转路由
  public redirect?: string;

  public parentId?: number;

  // 子路由
  public children?: BaseRotueVo[];

}

export interface BaseRouteState {
  _noPermissionRoutes?: BaseRotueVo[];
  _permissionRoutes?: BaseRotueVo[];
  tenantMap?: {
    [key: number]: {
      noPermissionRoutes?: BaseRotueVo[];
      permissionRoutes?: BaseRotueVo[];
    }
  }

}

const flatRoute = (routes: BaseRotueVo[] = []) => {
  const res: BaseRotueVo[] = [];
  routes.forEach((item) => {
    res.push(item);
    if (item.children?.length) {
      res.push(...flatRoute(item.children));
    }
  });
  return res;
};

export const useBaseRouteStore = defineStore({
  id: 'juoumanagebase-route',
  state: (): BaseRouteState => ({
    _noPermissionRoutes: undefined,
    _permissionRoutes: undefined,
    tenantMap: {},
  }),
  getters: {
    noPermissionRoutes: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.noPermissionRoutes : state._noPermissionRoutes;
    },
    permissionRoutes: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.permissionRoutes : state._permissionRoutes;
    },
    flatNoPermissionRoutes() {
      return flatRoute(this.noPermissionRoutes as any);
    },
    flatPermissionRoutes() {
      return flatRoute(this.permissionRoutes as any);
    },
    flatAllRoutes() {
      return [...this.flatNoPermissionRoutes as any, ...this.flatPermissionRoutes as any];
    },
    flatCommonLayoutRoutes() {
      return (this.flatAllRoutes as any).filter((item: any) => item.type === 'common_page');
    },
    flatAdminLayoutRoutes() {
      return (this.flatAllRoutes as any).filter((item: any) => item.type === 'admin_page');
    },
  },
  actions: {
    async getNoPermissionRoutes() {
      try {
        const res = await useApi().baseRouteApi.getNoPermissionRoutesBaseRoute();
        console.log(res.data.data);
        if (res.data.data) {
          const tenantId = useBaseTenantStore().tenantInfo?.id;
          if (tenantId) {
            let tenantRouteInfo = this.tenantMap?.[tenantId];
            if (!tenantRouteInfo) {
              tenantRouteInfo = {};
              this.tenantMap![tenantId] = tenantRouteInfo;
            }
            tenantRouteInfo!.noPermissionRoutes = res.data.data;
          } else {
            this._noPermissionRoutes = res.data.data;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getPermissionRoutes() {
      try {
        const res = await useApi().baseRouteApi.getPermissionRoutesBaseRoute();
        console.log(res.data.data);
        if (res.data.data) {
          const tenantId = useBaseTenantStore().tenantInfo?.id;
          if (tenantId) {
            let tenantRouteInfo = this.tenantMap?.[tenantId];
            if (!tenantRouteInfo) {
              tenantRouteInfo = {};
              this.tenantMap![tenantId] = tenantRouteInfo;
            }
            tenantRouteInfo!.permissionRoutes = res.data.data;
          } else {
            this._permissionRoutes = res.data.data;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    resetPermissionRoutes() {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      if (tenantId) {
        let tenantRouteInfo = this.tenantMap?.[tenantId];
        if (!tenantRouteInfo) {
          tenantRouteInfo = {};
          this.tenantMap![tenantId] = tenantRouteInfo;
        }
        tenantRouteInfo!.permissionRoutes = undefined;
      } else {
        this._permissionRoutes = undefined;
      }
    },
  },
});
