import { defineStore } from 'pinia';
import { piniaPersistConfig } from '../config';
import { useBaseTenantStore } from './baseTenantStore';

export interface BaseConfigState {
  _siteName?: string;
  _logo?: string;
  _description?: string;
  _topNav: boolean;
  tenantMap?: {
    [key: number]: {
      siteName?: string;
      logo?: string;
      description?: string;
      topNav: boolean;
    }
  }
}

export const useBaseConfigStore = defineStore({
  id: 'juoumanagebase-config',
  state: (): BaseConfigState => ({
    _siteName: undefined,
    _logo: undefined,
    _description: undefined,
    _topNav: false,
    tenantMap: {},
  }),
  getters: {
    siteName: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.siteName : state._siteName;
    },
    logo: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.logo : state._logo;
    },
    description: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.description : state._description;
    },
    topNav: (state) => {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      return tenantId ? state.tenantMap?.[tenantId]?.topNav : state._topNav;
    },
  },
  actions: {
    setSiteName(_v: string) {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      if (tenantId) {
        let tenantConfigInfo = this.tenantMap?.[tenantId];
        if (!tenantConfigInfo) {
          tenantConfigInfo = { topNav: false };
          this.tenantMap![tenantId] = tenantConfigInfo;
        }
        tenantConfigInfo!.siteName = _v;
      } else {
        this._siteName = _v;
      }
    },
    setLogo(_v: string) {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      if (tenantId) {
        let tenantConfigInfo = this.tenantMap?.[tenantId];
        if (!tenantConfigInfo) {
          tenantConfigInfo = { topNav: false };
          this.tenantMap![tenantId] = tenantConfigInfo;
        }
        tenantConfigInfo!.logo = _v;
      } else {
        this._logo = _v;
      }
    },
    setDescription(_v: string) {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      if (tenantId) {
        let tenantConfigInfo = this.tenantMap?.[tenantId];
        if (!tenantConfigInfo) {
          tenantConfigInfo = { topNav: false };
          this.tenantMap![tenantId] = tenantConfigInfo;
        }
        tenantConfigInfo!.description = _v;
      } else {
        this._description = _v;
      }
    },
    setTopNav(_v: boolean) {
      const tenantId = useBaseTenantStore().tenantInfo?.id;
      if (tenantId) {
        let tenantConfigInfo = this.tenantMap?.[tenantId];
        if (!tenantConfigInfo) {
          tenantConfigInfo = { topNav: false };
          this.tenantMap![tenantId] = tenantConfigInfo;
        }
        tenantConfigInfo!.topNav = _v;
      } else {
        this._topNav = _v;
      }
    },
  },
  persist: piniaPersistConfig('juoumanagebase-config'),
});
